/* main color: #ce1320  */

body {
  background-color: #edefef !important;
  color: #5e5e5e !important;
}

img {
  max-width:100%;
}

.App {
  text-align: center;
  padding-bottom: 75px;
}

.App .mainButton{
  border-radius:25px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: right;
  background-color: #ffffff;
  margin-bottom: 10px;
  border-bottom: 1px solid lightgray;
}

.App-header img {
  margin: 20px 0;
  max-width:100%;
}

.App-header .kontaktdaten{
  text-align: left;
  margin-top:15px !important;
}

.headerSearch {
  width: 100%;
}

.card {
  box-shadow: 0px 0px 19px #d7d7d7;
  -webkit-box-shadow: 0px 0px 19px #d7d7d7;
  -moz-box-shadow: 0px 0px 19px #d7d7d7;
 /* background-color:white !important; */
}

.card-body h3{
  margin-bottom:1.0rem;
  margin-top: 0.5rem;
}

.footermenu {
  position: fixed;
  bottom: 0;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  padding: 10px;
  background-color: #ffffff;
}

.mainButton {
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
  box-shadow: 0px 0px 13px #b3b3b3;
  -webkit-box-shadow: 0px 0px 13px #b3b3b3;
  -moz-box-shadow: 0px 0px 13px #b3b3b3;
  background-color: #ce1320 !important
}

.mainButton a,
.mainButton a:hover {
  color: unset;
  text-decoration: unset;
}

.mainButton:active {
  /*background-color:#ffffff !important;*/
  filter: brightness(1.1);
  transition: all 0.5s ease-in-out;
}

.mainButton .title {
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
  margin: 0px 0 0 0;

}



.mainButton .icon {
  background-color: #17712d;
  background-color: #ce1320;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  padding: 12px;
  margin: 0 auto;
}

.mainButton .icon-red {
  /*background-color: #ce1320;*/
  border-radius: 50%;
  width: 80px;
  height: 80px;
  padding: 12px;
  margin: 0 auto;
}

.mainButton .icon img,
.mainButton .icon-red img {
  width: 100%;
  height: 100%;
}

.mainButton .col{
  padding:0;
}

.menuItem {
  margin: 0;
}

.menuItem a,
.menuItem a:hover {
  color: #5c5c5c;
  text-decoration: none;
}

.openingHours {
  text-align: left;
  margin: 10px 0;
}

.openingHours .day {
  font-weight: bold;
}

.openingHours .time {
  text-align: right;
}

.kontaktdaten .icon{
  color: #ce1320;
}

.kontaktdaten .text{
  padding-left:0px;
  padding-left:15px;
  width: fit-content;
}

.kontaktdaten a{
  text-decoration:none;
  color: inherit;
}

.logozeile{
  width:50%;
}

.contact {
  text-align: left;
}

.contact .icon {
  color: #ce1320;
  font-size: 20px;
  text-align: center;
}

.powered {
  text-align: center;
  font-size: 20px;
  margin-bottom:0;
  padding-bottom: 0px;
}

.empfehlung a {
  text-decoration: none;
  color: unset;
}

.empfehlung .container {
  padding: 0;
}

.openingHours,
.contact {
  margin-top: 20px;
}

@media  screen and (max-width: 768px) {
  .App-header .logozeile{
    width:35%;
    max-width:35%;
  }

}

@media  screen and (max-width: 480px) {
.kontaktdaten .text {
  padding-left: 10px;
}
.kontaktzeile{
  padding-right:0px !important;
}
}

.App .footermenu{
  width:100%;
  max-width:100%;
}

.navlink--{
  display: contents;
}

.App a{
  display: contents;
  color: #5b5b5b;
}

.Footer-menu{
  margin-left:20px;
  display: inline !important;
}

.push-news{
  background-color:#ffffff !important;
  text-align:left;
}

.push-news strong{
  margin-bottom:5px;
  display: block;
  ;
}

/*  Für Präsentation  */

/*
.mainButton {
  background-color: #f96302!important;
}

.mainButton .icon {
  background-color: #f96302!important;
}

.contact .icon {
  color: #f96302;
}

*/